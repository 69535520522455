import {
  GetAppManifestFn,
  EditorReadyFn,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import { AppExposedApis } from '@wix/platform-editor-sdk';
import { configureManifest } from './manifest';
import { getTranslateFunction } from './i18n';
import { openDashboard } from './utils/openDashboard';
import { openSettings } from './utils/openSettings';
import ActionsId from './utils/ActionsId';

let _translate: any;
let activeTab = 'HOME';

const openSettingWithCurrentTab = (
  currentTab: string,
  editorSDK: FlowEditorSDK,
) => {
  activeTab = currentTab;
  setTimeout(() => {
    activeTab = 'HOME';
  }, 15000);
  openSettings(editorSDK);
};

export const editorReady: EditorReadyFn = async (
  editorSDK,
  token,
  { initialAppData },
) => {
  console.log('FAQ editorReady function', initialAppData.languageCode || 'en');
  _translate = await getTranslateFunction(initialAppData.languageCode || 'en');
  editorSDK.addEventListener('appActionClicked', (event) => {
    switch (event.detail.actionId) {
      case ActionsId.ADD_QUESTION:
        openDashboard(editorSDK, true);
        break;
      case ActionsId.MANAGE_FAQS:
        openDashboard(editorSDK);
        break;
      case ActionsId.CUSTOMIZE_DESIGN:
        openSettingWithCurrentTab('DESIGN', editorSDK);
        break;
      case ActionsId.CUSTOMIZE_DISPLAY:
        openSettingWithCurrentTab('DISPLAY', editorSDK);
        break;
      case ActionsId.CUSTOMIZE_LAYOUT:
        openSettingWithCurrentTab('LAYOUT', editorSDK);
        break;
      default:
        break;
    }
  });
};

export const getAppManifest: GetAppManifestFn = (
  { appManifestBuilder },
  editorSDK,
) => {
  configureManifest(appManifestBuilder, editorSDK, _translate);

  return appManifestBuilder.build();
};

export const exports = (): AppExposedApis => ({
  private: {
    getActiveTab: () => activeTab,
  },
});
