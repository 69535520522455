import { Actions } from '../types/types';

const ActionsId: Record<Actions, string> = {
  ADD_QUESTION: 'Add Question & Answer',
  MANAGE_FAQS: 'Manage FAQs',
  CUSTOMIZE_DESIGN: 'Customize Design',
  CUSTOMIZE_DISPLAY: 'Customize Display',
  CUSTOMIZE_LAYOUT: 'Customize Layout',
};

export default ActionsId;
