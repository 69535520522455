import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const openDashboard = (
  editorSDK: FlowEditorSDK,
  isAddQuestion?: boolean,
) => {
  const url = isAddQuestion
    ? 'faq-page-bm/14c92d28-031e-7910-c9a8-a670011e062d?isAddQuestion=true'
    : 'faq-page-bm/14c92d28-031e-7910-c9a8-a670011e062d';

  editorSDK.editor.openDashboardPanel('', {
    url,
    closeOtherPanels: false,
  });
};
