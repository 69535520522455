import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const openSettings = async (editorSDK: FlowEditorSDK) => {
  const FAQappId = '14c92d28-031e-7910-c9a8-a670011e062d';
  const appData = await editorSDK.document.tpa.app.getDataByAppDefId(
    '',
    FAQappId,
  );

  const applicationId = appData && appData.applicationId;

  const appComponents =
    applicationId &&
    (await editorSDK.document.tpa.app.getAllCompsByApplicationId(
      '',
      applicationId,
    ));

  const settingsFAQId =
    appComponents && appComponents.length && appComponents[0].id;

  settingsFAQId &&
    (await editorSDK.editor.deeplink.show('', {
      type: 'component',
      params: [settingsFAQId, 'settings'],
    }));
};
